const dev = {
    backendServer: 'https://api.roborockservice.com.au/torn/'
};
const uat = {
    backendServer: 'https://api.roborock.prasadh.com/torn/'
};
const prod = {
    backendServer: 'https://api.roborockservice.com.au/torn/'
};

const config = process.env.REACT_APP_ENV === 'prod' ? prod :
    process.env.REACT_APP_ENV === 'uat' ? uat : dev;

export default {
    // Add common config values here
    ...config
};
