import React, { Component } from "react";
import "./AddNewWarranty.css";
import "../../common.css";
import "../../simple-grid.css";
import config from '../../../config';
import {Redirect} from "react-router-dom";
import LoadingPage from "../../../components/Loading/Loading";
import errorFunctions from "../../../store/errorFunctions";
import FileInput from "../../../components/FileInput/FileInput";

export default class AddNewWarranty extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            isLoading: true
        };
    };

    componentDidMount() {
        this.props.protectedFetch(config.backendServer+'retrievewarrantyfields', null, errorFunctions.checkResponseThenJson)
            .then(res => {
                this.inputFields = res;

                // Add each input field to state so inputs are controlled
                const addToState = [];
                for (const input of res) {
                    if (input.type === 'checkboxes') {
                        addToState[input.id] = [];
                    } else if (input.type === 'select') {
                        addToState[input.id] = input.options[0];
                    } else {
                        addToState[input.id] = '';
                    }
                }

                // On resolved and rejected, need to take off loading screen
                this.setState({isLoading: false, ...addToState});
            }).catch(()=> {
            console.log('fail');
        });
    }

    handleCheckboxChange = e => {
        const { name, value, checked } = e.target;
        let values = this.state[name],
            valueExists = values.indexOf(value);

        if (checked) {
            // Add if doesn't exist
            if (valueExists === -1) {
                values.push(value);
            }
        } else {
            // Remove if does exist
            if (valueExists > -1) {
                values.splice(valueExists, 1);
            }
        }

        this.setState({[name]: values});
    };
    handleInputChange = e => {
        const { id, value } = e.target;

        this.setState({
            [id]: value
        });
    };
    handleFileChange = (id, file) => {
        this.setState({
            [id]: file
        });
    };
    handleSubmit = e => {
        this.setState({isLoading: true});
        e.preventDefault();
        let formData = new FormData();

        for (const input of this.inputFields) {
            if (this.state[input.id] && (this.state[input.id].length > 0 || input.type === 'fileInput')) {
                if (input.type === 'checkboxes') {
                    formData.append(input.id, JSON.stringify(this.state[input.id]));
                } else {
                    formData.append(input.id, this.state[input.id]);
                }
            }
        }

        this.props.protectedFetch(config.backendServer+'addnewwarranty', formData, errorFunctions.checkResponseThenJson, true)
            .then(res => {
                // On resolved and rejected, need to take off loading screen
                this.setState({redirect: res.id, isLoading: false});
            }).catch(()=> {
            console.log('fail');
        });
    };

    render() {
        if (this.state.isLoading) {
            return <LoadingPage small={true}/>;
        } else if (this.state.redirect) {
            return <Redirect to={"/warranty/"+this.state.redirect}/>;
        }

        const inputFields = this.inputFields;

        return <div className={"page add-new-warranty-page"}>
            <h2 className={"pageHeader"}>Submit Warranty Request</h2>
            <div className={"pageContent"}>
                <form onSubmit={this.handleSubmit}>
                    {inputFields.map((item, i) => {
                        if (item.type === 'text') {
                            return <div key={i} className={`form-field ${item.widthClass}`}>
                                <label>{item.name}</label>
                                <input
                                    type={"text"}
                                    value={this.state[item.id]}
                                    onChange={this.handleInputChange}
                                    id={item.id}
                                />
                            </div>
                        } else if (item.type === 'select') {
                            return <div key={i} className={`form-field ${item.widthClass}`}>
                                <label>{item.name}</label>
                                <select
                                    id={item.id}
                                    onChange={this.handleInputChange}
                                    value={item.options.indexOf(this.state[item.id]) === -1 ? 'Other' : this.state[item.id]}
                                >
                                    {item.options.map((option, j) => {
                                        return <option key={j}>{option}</option>
                                    })}
                                </select>
                                {/* If select option is other, replace state with name in input. Fancy OR statement handles this */}
                                {(this.state[item.id] === 'Other' || item.options.indexOf(this.state[item.id]) === -1) && <input
                                    type={"text"}
                                    required={true}
                                    placeholder={"Type device name here"}
                                    onChange={this.handleInputChange}
                                    id={item.id}
                                    value={this.state[item.id]==='Other' ? '' : this.state[item.id]}
                                />}
                            </div>
                        } else if (item.type === 'checkboxes') {
                            return <div key={i} className={`form-field ${item.widthClass}`}>
                                <div className={"add-new-warranty-special-input-header"}>Reason of return:</div>
                                {item.options.map((text, i) => {
                                    return <label
                                        className={`add-new-warranty-checkbox-container ${text.length > 50 ? 'add-new-warranty-checkbox-container-full-width' : ''}`}
                                        key={i}
                                    >
                                        <input
                                            type="checkbox"
                                            id={item.id+i}
                                            name={item.id}
                                            value={text}
                                            onChange={this.handleCheckboxChange}
                                        />
                                        <span className={"add-new-warranty-checkbox-text"}>{text}</span>
                                    </label>
                                })}
                            </div>
                        } else if (item.type === 'textarea') {
                            return <div key={i} className={`form-field ${item.widthClass}`}>
                                <label>{item.name}</label>
                                <textarea
                                    value={this.state[item.id]}
                                    onChange={this.handleInputChange}
                                    id={item.id}
                                />
                            </div>
                        } else if (item.type === 'fileInput') {
                            return <div key={i} className={`form-field ${item.widthClass}`}>
                                <label><b>{item.name}</b></label>
                                <FileInput
                                    onChange={(file) => this.handleFileChange(item.id, file)}
                                />
                            </div>
                        }
                        return null;
                    })}
                    <button
                        className="form-submit"
                        type="submit"
                    >Submit</button>
                </form>
            </div>
        </div>
    }
}