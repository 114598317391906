import React, { Component } from "react";
import rightBackground from "../../img/robot-background.jpg";
import logoIcon from "../../img/logo-icon.png"
import './Authentication.css';

export default class AuthContainer extends Component {
    resize = () => {
        this.forceUpdate();
    };
    componentDidMount() {
        window.addEventListener("resize", this.resize);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
    }

    render() {
        let width = window.innerWidth;
        let backgroundImgStyle = {
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundImage: `url(${rightBackground})`
        };

        return <div className={"auth-container clearfix"}>
            <div className={"auth-container-input-side"}>
                <div className={"auth-input-side-stuff-holder"}>{this.props.children}</div>
            </div>
            {width > 450 && (
                <div style={backgroundImgStyle} className={"auth-container-img-side"}>
                    <div className={"centredLogoBox"}>
                        <img alt={"logo"} className={"centredLogoIcon"} src={logoIcon}/>
                    </div>
                </div>
            )}
        </div>
    }
}
