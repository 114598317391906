import React, { Component } from "react";
import "./Users.css";
import "../common.css";
import config from '../../config';
import { Link } from "react-router-dom";
import errorFunctions from "../../store/errorFunctions";
import LoadingPage from "../../components/Loading/Loading";
import {FiUser, FiUserCheck, FiUserX} from "react-icons/fi";
import {TiArrowSortedDown, TiArrowSortedUp} from "react-icons/ti";
import utilityFunctions from "../../store/utilityFunctions";
import Table from "../../components/Table/Table";

export default class Users extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            users: [],
            searchFilter: '',
            verifiedFilter: 'All'
        };
    };

    componentDidMount() {
        this.retrieveData();
    };
    retrieveData = () => {
        this.setState({loading: true});
        this.props.protectedFetch(config.backendServer + 'getusers', null, errorFunctions.checkResponseThenJson)
            .then(res => {
                this.setState({loading: false, users: res});
            }).catch(() => {
            console.log('fail');
            this.setState({loading: false});
        });
    };
    filterChange = e => {
        this.setState({[e.target.id]: e.target.value});
    };
    verifiedFilterChange = value => {
        this.setState({verifiedFilter: value});
    };

    render() {
        if (this.state.loading) {
            return <LoadingPage/>
        }

        let users = this.state.users;

        // Filter out users
        let filteredUsers = [];
        for (const user of users) {
            // Check verified filter
            if (
                this.state.verifiedFilter === 'All'
                || this.state.verifiedFilter === user.is_verified
            ) {
                // check search filter (on name and email)
                if (
                    !this.state.searchFilter
                    || (user.name && user.name.toLowerCase().indexOf(this.state.searchFilter.toLowerCase()) > -1)
                    || (user.email && user.email.toLowerCase().indexOf(this.state.searchFilter.toLowerCase()) > -1)
                ) {
                    filteredUsers.push(user);
                }
            }
        }

        const tableHead = [
            {id: 'id', label: 'ID', valueDisplay: (value) => <Link to={"/user/"+value}>{value}</Link>},
            {id: 'email', label: 'Email', valueDisplay: (value) => value},
            {id: 'name', label: 'Name', valueDisplay: (value) => value || '-'},
            {id: 'is_verified', label: 'Verified', valueDisplay: (value) => value ? 'Yes' : 'No'},
            {id: 'date_created', label: 'Date Created', valueDisplay: (value) => value},
            {id: 'last_login', label: 'Last Login', valueDisplay: (value) => value}
        ];

        return <div className={"page"}>
            <h1 className={"pageHeader"}>Users</h1>
            <div className={"admin-summary-area"}>
                <div
                    className={`users-summary-box ${this.state.verifiedFilter === 'All' ? 'users-summary-box-active' : ''}`}
                    onClick={() => this.verifiedFilterChange('All')}
                    style={{color: '#192A56'}}
                >
                    <FiUser className={"users-summary-icon"}/>
                    <div className={"users-summary-container"}>
                        <span className={"users-summary-title"}>All</span>
                        <span className={"users-summary-value"}>{users.length}</span>
                    </div>
                </div>
                <div
                    className={`users-summary-box ${this.state.verifiedFilter === true ? 'users-summary-box-active' : ''}`}
                    onClick={() => this.verifiedFilterChange(true)}
                    style={{color: '#019031'}}
                >
                    <FiUserCheck className={"users-summary-icon"}/>
                    <div className={"users-summary-container"}>
                        <span className={"users-summary-title"}>Verified</span>
                        <span className={"users-summary-value"}>{users.filter((obj) => obj.is_verified === true).length}</span>
                    </div>
                </div>
                <div
                    className={`users-summary-box ${this.state.verifiedFilter === false ? 'users-summary-box-active' : ''}`}
                    onClick={() => this.verifiedFilterChange(false)}
                    style={{color: '#E71C23'}}
                >
                    <FiUserX className={"users-summary-icon"}/>
                    <div className={"users-summary-container"}>
                        <span className={"users-summary-title"}>Unverified</span>
                        <span className={"users-summary-value"}>{users.filter((obj) => obj.is_verified === false).length}</span>
                    </div>
                </div>
            </div>
            <div className={"admin-filter-area"}>
                <div className={"admin-filter-input-container"}>
                    <span className={"admin-filter-input-label"}>Search: </span>
                    <input
                        type={"text"}
                        id={"searchFilter"}
                        className={"admin-filter-input"}
                        onChange={this.filterChange}
                        value={this.state.searchFilter}
                    />
                </div>
            </div>
            <div className={"pageContent clearfix"}>
                {users.length === 0 && <div>No users</div>}
                {users.length > 0 && <div className={"admin-table-container"}>
                    <Table
                        head={tableHead}
                        data={filteredUsers}
                    />
                </div>}
            </div>
        </div>
    }
}