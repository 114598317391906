import React, { Component } from "react";
import "./FileInput.css";
import Dropzone from 'react-dropzone';

export default class FileInput extends Component {
    constructor() {
        super();

        this.state = {
            file: null
        };
    }

    onDrop = (files) => {
        const file = files[files.length - 1];
        this.props.onChange(file);
        this.setState({file});
    }

    render() {
        let fileName;
        if (this.state.file) {
            fileName = this.state.file.path;
        }

        return (
            <Dropzone onDrop={this.onDrop}>
                {({getRootProps, getInputProps}) => (
                    <section className="fileInputContainer">
                        <div {...getRootProps({className: 'fileInputDropzone'})}>
                            <input {...getInputProps()} />
                            {fileName
                                ? <p style={{color: '#333333'}}>{fileName}</p>
                                : <p>Drag 'n' drop some files here, or click to select files</p>
                            }
                        </div>
                    </section>
                )}
            </Dropzone>
        );
    }
}