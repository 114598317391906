import React, { Component } from "react";
import './PageWithNavbar.css';
import Navbar from "../../components/Navbar/Navbar";
//import PageFooter from "../PageFooter";

export default class PageWithNavbar extends Component {
    render() {
        return (<div className="fullPageContainer">
            <Navbar
                logout={this.props.logout}
                isSuper={this.props.isSuper}
            />
            <div className="fullPageChildrenHolder">
                {this.props.children}
            </div>

            {/*<PageFooter/>*/}
        </div>)
    }
}