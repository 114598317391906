import React, { Component } from "react";
import "./Authentication.css";
import { Link, Redirect } from "react-router-dom";
import LoadingPage from "../../components/Loading/Loading";
import config from "../../config";

export default class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            username: "",
            password: "",
            rememberMe: false,
            invalidLoginTriggered: false,
            redirect: false
        };
    }


    validateForm() {
        if (this.state.username.length === 0) {
            this.setState({
                invalidLoginTriggered: "Please provide a username."
            });
        } else if (this.state.password.length === 0) {
            this.setState({
                invalidLoginTriggered: "Please provide a password."
            });
        }
        else {
            return true;
        }

        return false;  //If not in else, then it's failed
    };

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleCheckboxChange = event => {
        this.setState({
            [event.target.id]: event.target.checked
        });
    };

    handleSubmit = async event => {
        event.preventDefault();

        const formValidated = this.validateForm();

        if (formValidated) {
            this.setState({ isLoading: true });

            let url = config.backendServer+'login';

            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    password: this.state.password,
                    username: this.state.username
                })
            }).then(response => {
                if (response.status === 200) {
                    return response.text();

                } else if (response.status === 410) {
                    this.setState({
                        isLoading: false,
                        invalidLoginTriggered: "There appears to be an error in the username or password.",
                        email: "",
                        password: ""
                    })
                } else {
                    alert("Problem with system. Please refresh and try again later.");
                }
            }).then(text=> {
                if (text) {
                    // All fails should be handled in above function
                    this.props.onLogin(text, this.state.rememberMe, () => {
                        this.setState({redirect: true, isLoading: false});
                    });
                }
            });
        }
    };


    render() {
        if (this.state.isLoading) {
            return <LoadingPage small={true}/>;
        } else if (this.state.redirect) {
            return <Redirect to={"/"}/>;
        }
        return (
            <div>
                <h2 className={"auth-page-header"}>Login</h2>
                {this.state.invalidLoginTriggered && (
                    <div className={"incorrectAuthenticationText"}>
                        {this.state.invalidLoginTriggered}
                    </div>
                )}
                <form onSubmit={this.handleSubmit}>
                    <div className="auth-form-field">
                        <label>Username</label>
                        <input
                            type={"text"}
                            required={true}
                            id={"username"}
                            value={this.state.username}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="auth-form-field">
                        <label>Password</label>
                        <input
                            type={"password"}
                            required={true}
                            id={"password"}
                            value={this.state.password}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className={"auth-options-section clearfix"}>
                        <label className="auth-checkbox-field">
                            Remember Me
                            <input type="checkbox"
                                   id="rememberMe"
                                   onChange={this.handleCheckboxChange}
                                   checked={this.state.rememberMe}
                            />
                            <span className="checkmark"/>
                        </label>
                    </div>
                    <button
                        className="auth-form-submit"
                        type="submit"
                    >Login</button>
                </form>
            </div>
        );
    }
}
