import React, { Component } from "react";
import "./Warranties.css";
import "../common.css";
import config from '../../config';
import { Link } from "react-router-dom";
import errorFunctions from "../../store/errorFunctions";
import LoadingPage from "../../components/Loading/Loading";
import {
    IoMdCode as OpenIcon,
    IoMdCodeWorking as AllIcon,
    IoIosBrush as CreatedIcon,
    IoIosThumbsUp as ApprovedIcon,
    IoIosRocket as InTransitIcon,
    IoIosTimer as ReviewIcon,
    IoIosThunderstorm as ServiceIcon,
    IoMdCloud as CompleteIcon,
    IoIosThumbsDown as DeclinedIcon,
    IoIosClipboard as OutOfWarrantyIcon
} from "react-icons/io";
import {MdWarning} from "react-icons/md";
import utilityFunctions from "../../store/utilityFunctions";
import Table from "../../components/Table/Table";
import { FaPlus } from "react-icons/fa";

export default class Warranties extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            warranties: [],
            statusFilter: 'Open',
            searchFilter: ''
        };
    };

    componentDidMount() {
        this.retrieveWarranties();
    };
    retrieveWarranties = () => {
        this.setState({loading: true});
            this.props.protectedFetch(config.backendServer + 'getwarranties', null, errorFunctions.checkResponseThenJson)
                .then(res => {
                    this.setState({loading: false, warranties: res});
                }).catch(() => {
                console.log('fail');
                this.setState({loading: false});
            });
    };
    filterChange = e => {
        this.setState({[e.target.id]: e.target.value});
    };
    statusFilterChange = value => {
        this.setState({statusFilter: value});
    };

    render() {
        if (this.state.loading) {
            return <LoadingPage/>
        }
        let warranties = this.state.warranties;

        const openOptions = ['Awaiting Approval', 'Approved', 'In transit', 'Under review', 'Under servicing', 'Customer Review'];

        const statusSelectors = [
            /*{
                name: 'Awaiting Approval',
                icon: <CreatedIcon className={"warranties-summary-individual-icon"}/>,
                color: '#2475B0'
            },*/ {
                name: 'Approved',
                icon: <ApprovedIcon className={"warranties-summary-individual-icon"}/>,
                color: '#00695C'
            },/* {
                name: 'In transit',
                icon: <InTransitIcon className={"warranties-summary-individual-icon"}/>,
                color: '#1A237E'
            },*/ {
                name: 'Under review',
                icon: <ReviewIcon className={"warranties-summary-individual-icon"}/>,
                color: '#B71C1C'
            }, {
                name: 'Under servicing',
                icon: <ServiceIcon className={"warranties-summary-individual-icon"}/>,
                color: '#E65100'
            }, {
                name: 'Complete',
                icon: <CompleteIcon className={"warranties-summary-individual-icon"}/>,
                color: '#212121'
            }/*, {
                name: 'Declined',
                icon: <DeclinedIcon className={"warranties-summary-individual-icon"}/>,
                color: '#D50000'
            }, {
                name: 'Customer Review',
                icon: <OutOfWarrantyIcon className={"warranties-summary-individual-icon"}/>,
                color: '#4A148C'
            }*/
        ];

        // Filter out warranties
        let filteredWarranties = [];
        for (const warranty of warranties) {
            /*
            What does following status filter do:
            - If All, then show all warranties
            - If Open, then show all warranties that have statuses defined as 'Open'
            - Else match statuses
            */
            if (
                this.state.statusFilter === 'All'
                || (this.state.statusFilter === 'Open' && openOptions.indexOf(warranty.status) > -1)
                || this.state.statusFilter === warranty.status
            ) {
                // Now check search filter (on name and serial number)
                if (
                    !this.state.searchFilter
                    || (warranty.name && warranty.name.toLowerCase().indexOf(this.state.searchFilter.toLowerCase()) > -1)
                    || (warranty.serial_number && warranty.serial_number.toLowerCase().indexOf(this.state.searchFilter.toLowerCase()) > -1)
                    || (warranty.ticket_number && warranty.ticket_number.toLowerCase().indexOf(this.state.searchFilter.toLowerCase()) > -1)
                    || (warranty.email && warranty.email.toLowerCase().indexOf(this.state.searchFilter.toLowerCase()) > -1)
                    || (warranty.id && warranty.id.toString().indexOf(this.state.searchFilter.toLowerCase()) > -1)
                ) {
                    const warrantyDisplay = utilityFunctions.cloneObject(warranty);
                    warrantyDisplay.id = <Link to={"/warranty/"+warranty.id}>{warranty.id}</Link>;
                    warrantyDisplay.name = warranty.name || '(missing)';
                    warrantyDisplay.out_of_warranty = <MdWarning style={{paddingTop: '12px'}} title={'Device is out of warranty'}/>;

                    filteredWarranties.push(warranty);
                }
            }
        }

        const tableHead = [
            {id: 'id', label: 'ID', width: '40px', valueDisplay: (value) => <Link to={"/warranty/"+value}>{value}</Link>},
            {id: 'date', label: 'Date', width: '140px', defaultSort: 'desc', valueDisplay: (value) => value},
            {id: 'ticket_number', label: 'Ticket ID', width: '125px', valueDisplay: (value) => value},
            {id: 'name', label: 'Owner Name', width: '210px', valueDisplay: (value) => value || '(missing)'},
            {id: 'email', label: 'Email', width: '250px', valueDisplay: (value) => value},
            //{id: 'serial_number', label: 'Serial Number', width: '230px', valueDisplay: (value) => value},
            {id: 'status', label: 'Status', width: '210px', valueDisplay: (value) => {
                const selector = statusSelectors.filter((item) => {return item.name == value;})[0];
                if (selector) {
                    return <span style={{
                        color: selector.color
                    }}>{selector.icon}
                        <span style={{fontSize: '0.8em'}}>{value}</span>
                    </span>;
                } else {
                    return null;
                }
            }},
            {id: 'out_of_warranty', label: '', width: '20px', valueDisplay: (value) => value ? <MdWarning style={{verticalAlign: 'middle'}} title={'Device is out of warranty'}/> : null}
        ];

        return <div className={"page"}>
            {/* Add button for adding new 'My Device' */}
            <Link to="/addnewwarranty" className={"warranties-add-new"}>
                <FaPlus className={"warranties-add-new-icon"}/>
                <div className={"warranties-add-new-text"}>Add New</div>
            </Link>
            <h2 className={"pageHeader"}>Warranties</h2>
            <div className={"admin-summary-area"}>
                <div className={"warranties-summary-overall-selector-area"}>
                    <div
                        className={`warranties-summary-overall-selector-box ${this.state.statusFilter === 'Open' ? 'warranties-summary-box-active' : ''}`}
                        onClick={() => this.statusFilterChange('Open')}
                        style={{color: '#019031'}}
                    >
                        <OpenIcon className={"warranties-summary-overall-icon"}/>
                        <div className={"warranties-summary-overall-container"}>
                            <span className={"warranties-summary-overall-title"}>Open</span>
                            <span className={"warranties-summary-overall-value"}>{warranties.filter((obj) => openOptions.indexOf(obj.status) > -1).length}</span>
                        </div>
                    </div>
                    <div
                        className={`warranties-summary-overall-selector-box ${this.state.statusFilter === 'All' ? 'warranties-summary-box-active' : ''}`}
                        onClick={() => this.statusFilterChange('All')}
                        style={{color: '#192A56'}}
                    >
                        <AllIcon className={"warranties-summary-overall-icon"}/>
                        <div className={"warranties-summary-overall-container"}>
                            <span className={"warranties-summary-overall-title"}>All</span>
                            <span className={"warranties-summary-overall-value"}>{warranties.length}</span>
                        </div>
                    </div>
                </div>
                <div className={"warranties-summary-individual-selector-area"}>
                    {statusSelectors.map((item, i) => {
                        return <div
                            className={`warranties-summary-individual-selector-box ${this.state.statusFilter === item.name ? 'warranties-summary-box-active' : ''}`}
                            onClick={() => this.statusFilterChange(item.name)}
                            style={{color: item.color}}
                            key={i}
                        >
                            {item.icon}
                            <span className={"warranties-summary-individual-title"}>{item.name}</span>
                            <span className={"warranties-summary-individual-value"}>{warranties.filter((obj) => item.name === obj.status).length || '-'}</span>
                        </div>
                    })}
                </div>
            </div>
            <div className={"admin-filter-area"}>
                <div className={"admin-filter-input-container"}>
                    <span className={"admin-filter-input-label"}>Search: </span>
                    <input
                        type={"text"}
                        id={"searchFilter"}
                        className={"admin-filter-input"}
                        onChange={this.filterChange}
                        value={this.state.searchFilter}
                    />
                </div>
            </div>
            <div className={"pageContent clearfix"}>
                {warranties.length === 0 && <div>No warranties</div>}
                {warranties.length > 0 && <div className={"admin-table-container"}>
                    <Table
                        head={tableHead}
                        data={filteredWarranties}
                    />
                </div>}
            </div>
        </div>
    }
}
