import React, {Component} from "react";
import {BrowserRouter as Router, Route, Redirect, Switch} from "react-router-dom";
import Login from './pages/AuthenticationPages/Login';
import './App.css';
import AuthContainer from "./pages/AuthenticationPages/AuthContainer";
import NotFound from "./pages/NotFound/NotFound";
import utilityFunctions from "./store/utilityFunctions";
import LoadingPage from "./components/Loading/Loading";
import config from "./config";
import PageWithNavbar from "./pageContainers/PageWithNavbar/PageWithNavbar";
import Warranties from "./pages/Warranties/Warranties";
import SingleWarranty from "./pages/Warranties/SingleWarranty/SingleWarranty";
import HomeDashboard from "./pages/HomeDashboard/HomeDashboard";
import Users from "./pages/Users/Users";
import SingleUser from "./pages/Users/SingleUser/SingleUser";
import errorFunctions from "./store/errorFunctions";
import AddNewWarranty from "./pages/Warranties/AddNewWarranty/AddNewWarranty";

export default class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isVerified: false,  // Set to true if account is verified (in authentication check)
            isSuper: false  // On load, backend says if account is super admin or not
        };

        //Check if token exists, and if does set it
        const lgnToken =
            localStorage.getItem("lgn") || utilityFunctions.getCookie("lgn");
        if (typeof lgnToken === "string") {
            this.state.isAuthenticated = lgnToken;
        }
    };

    componentDidMount() {
        this.validateUser();
    }

    validateUser(callback) {
        // Check isAuthenticated and whether it is valid or not
        if (this.state.isAuthenticated) {
            // Need to validate token with server
            this.protectedFetch(config.backendServer + 'validatetoken', null, errorFunctions.checkResponseThenJson)
                .then(res => {
                    // On resolved and rejected, need to take off loading screen
                    this.setState({
                        isLoading: false,
                        isVerified: true,
                        isSuper: !!res.super /* force boolean */
                    }, () => {
                        if (callback) {
                            callback();
                        }
                    });
                }).catch(() => {
                this.setState({isLoading: false}, () => {
                    if (callback) {
                        callback();
                    }
                });
            });
        } else {
            // If not logged in, routes will handle rest
            this.setState({isLoading: false}, () => {
                if (callback) {
                    callback();
                }
            });
        }
    }

    userHasAuthenticated = (authenticated, rememberMe, callback) => {
        this.setState({isAuthenticated: authenticated});
        if (authenticated) {
            if (rememberMe) {
                localStorage.setItem("lgn", authenticated);
            } else {
                utilityFunctions.setCookie("lgn", authenticated);
            }
        }
        this.validateUser(() => {
            if (callback) {
                callback();
            }
        });
    };
    handleLogout = event => {
        this.userHasAuthenticated(false);
        this.setState({isAuthenticated: null});
        utilityFunctions.setCookie("lgn", "", -20); //Setting expires to past should delete cookie
        localStorage.removeItem("lgn");
    };

    protectedFetch = (url, data, checkFunction, formData) => {
        const token = this.state.isAuthenticated;
        const logout = this.handleLogout;
        return new Promise(function (resolve, reject) {
            let options;
            if (formData) {  // True / false
                options = {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                    body: data
                };
            } else {
                options = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(data || {})
                };
            }
            fetch(url, options).then(response => {
                // Check if token failure occurred - if so then trigger logout. Else use checkFunction
                if (response.status === 403) {
                    reject('token invalid');
                    logout();
                    return null;
                } else if (response.status === 413) {
                    // User is not verified, take to 'Account needs to be verified page'
                    reject('not verified');
                    return null;
                } else {
                    return checkFunction(response);
                }
            }).then(response => {
                if (response) {
                    resolve(response);
                } else {
                    reject('check function failed');
                }
            });
        });
    };

    render() {
        if (this.state.isLoading) {
            return <LoadingPage/>
        }
        return (
            <Router>
                <Switch>
                  {/* Use conditionals to control whether user has access to paths */}
                  {this.state.isSuper && <ProtectedRoute exact path="/" test={this.state.isAuthenticated}
                                    goToOnFailure={"/login"}
                                    render={(props) =>
                                        <PageWithNavbar
                                            logout={this.handleLogout}
                                            isSuper={this.state.isSuper}
                                        >
                                            <HomeDashboard protectedFetch={this.protectedFetch}/>
                                        </PageWithNavbar>}/>}
                  {/* Warranties is home for not super and not home for super */}
                    <ProtectedRoute exact path={this.state.isSuper ? "/warranties" : '/'}test={this.state.isAuthenticated}
                                    goToOnFailure={"/login"}
                                    render={(props) =>
                                        <PageWithNavbar
                                            logout={this.handleLogout}
                                            isSuper={this.state.isSuper}
                                        >
                                            <Warranties protectedFetch={this.protectedFetch}/>
                                        </PageWithNavbar>}/>
                    <ProtectedRoute exact path="/warranty/:id" test={this.state.isAuthenticated}
                                    goToOnFailure={"/login"}
                                    render={(props) =>
                                        <PageWithNavbar
                                            logout={this.handleLogout}
                                            isSuper={this.state.isSuper}
                                        >
                                            <SingleWarranty protectedFetch={this.protectedFetch} isSuper={this.state.isSuper} {...props}/>
                                        </PageWithNavbar>}/>
                    <ProtectedRoute exact path="/addnewwarranty" test={this.state.isAuthenticated}
                                    goToOnFailure={"/login"}
                                    render={(props) =>
                                        <PageWithNavbar
                                            logout={this.handleLogout}
                                            isSuper={this.state.isSuper}
                                        >
                                            <AddNewWarranty protectedFetch={this.protectedFetch} isSuper={this.state.isSuper} {...props}/>
                                        </PageWithNavbar>}/>
                  {this.state.isSuper && <ProtectedRoute exact path="/users" test={this.state.isAuthenticated}
                                    goToOnFailure={"/login"}
                                    render={(props) =>
                                        <PageWithNavbar
                                            logout={this.handleLogout}
                                            isSuper={this.state.isSuper}
                                        >
                                            <Users protectedFetch={this.protectedFetch}/>
                                        </PageWithNavbar>}/>}
                  {this.state.isSuper && <ProtectedRoute exact path="/user/:id" test={this.state.isAuthenticated}
                                    goToOnFailure={"/login"}
                                    render={(props) =>
                                        <PageWithNavbar
                                            logout={this.handleLogout}
                                            isSuper={this.state.isSuper}
                                        >
                                            <SingleUser protectedFetch={this.protectedFetch} {...props}/>
                                        </PageWithNavbar>}/>}
                    <ProtectedRoute exact path="/login" test={true}
                                    goToOnFailure={"/notfound"}
                                    render={(props) => <AuthContainer><Login
                                        onLogin={this.userHasAuthenticated} {...props}/></AuthContainer>}/>
                    {/* Finally, catch all unmatched routes */}
                    <Route component={NotFound}/>
                </Switch>
            </Router>
        );
    }
}

//Show if route shouldn't be available
class ProtectedRoute extends React.Component {
    render() {
        const {component: Component, ...props} = this.props;
        return (
            <Route
                {...props}
                render={props => (
                    !!this.props.test ?
                        this.props.render(this.props) :
                        <Redirect to={this.props.goToOnFailure}/>
                )}
            />
        )
    }
}

