import React, { Component } from "react";
import "./SingleWarranty.css";
import "../../common.css";
import config from '../../../config';
import LoadingPage from "../../../components/Loading/Loading";
import errorFunctions from "../../../store/errorFunctions";
import { MdClose } from "react-icons/md";
import Modal from 'react-modal';
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import utilityFunctions from "../../../store/utilityFunctions";
import {Redirect} from "react-router-dom";
Modal.setAppElement('body');

const inWarrantySteps = {
    'Approved': {
        'next': 'Under review',
        'buttonText': 'Mark as received',
        'modalText': 'Confirm that you have received the item.'
    }, 'In transit' : {
        'next': 'Under review',
        'buttonText': 'Mark as received',
        'modalText': 'Confirm that you have received the item.'
    }, 'Under review' : {
        'next': 'Under servicing',
        'buttonText': 'Review complete - mark as under service',
        'modalText': 'Confirm that item is ready to be serviced.'
    }, 'Under servicing' : {
        'next': 'Complete',
        'buttonText': 'Mark as completed',
        'modalText': 'Confirm that item is ready to be sent to customer.'
    }, 'Complete' : {

    }
};
const outOfWarrantySteps = {
    'Approved': {
        'next': 'Under review',
        'buttonText': 'Mark as received',
        'modalText': 'Confirm that you have received the item.'
    }, 'In transit' : {
        'next': 'Under review',
        'buttonText': 'Mark as received',
        'modalText': 'Confirm that you have received the item.'
    }, 'Under review' : {

    }, 'Approve invoice' : {
        'next': 'Under servicing',
        'buttonText': 'Payment received - mark as under service',
        'modalText': 'Confirm that item is ready to be serviced.'
    }, 'Awaiting payment' : {
        'next': 'Under servicing',
        'buttonText': 'Payment received - mark as under service',
        'modalText': 'Confirm that item is ready to be serviced.'
    }, 'Under servicing' : {
        'next': 'Complete',
        'buttonText': 'Mark as completed',
        'modalText': 'Confirm that item is ready to be sent to customer.'
    }, 'Complete' : {

    }
};

export default class SingleWarranty extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            details: null,
            id: null,
            showApproveModal: false,
            showDeclineModal: false,
            addComment: '',
            startPostageNumber: '',
            endPostageNumber: '',
            showDeleteModal: false,
            redirect: false,
            showOutOfWarrantyModal: false
        };
    };

    componentDidMount() {
        const id = this.props.computedMatch.params.id;
        this.setState({id});
        this.retrieveWarranty(id);
    };
    retrieveWarranty = id => {
        this.setState({loading: true});
        this.props.protectedFetch(config.backendServer+'getsinglewarranty', {id}, errorFunctions.checkResponseThenJson)
            .then(res => {
                this.setState({loading: false, details: res});
            }).catch(()=> {
            console.log('fail');
            this.setState({loading: false});
        });
    };

    handleInputChange = e => {
        const { id, value } = e.target;

        this.setState({
            [id]: value
        });
    };
    handleComment = e => {
        this.setState({loading: true});
        e.preventDefault();
        console.log(this.state.addComment);

        const dataToSend = {
            id: this.state.id,
            comment: this.state.addComment
        };

        this.props.protectedFetch(config.backendServer+'addcommenttowarranty', dataToSend, errorFunctions.checkResponseSent)
            .then(res => {
                this.setState({addComment: ''});
                this.retrieveWarranty(dataToSend.id);
            }).catch(()=> {
            console.log('fail');
            this.setState({loading: false});
        });
    };
    handleDecline = e => {
        this.setState({loading: true, showDeclineModal: false});
        e.preventDefault();
        const dataToSend = {
            id: this.state.id,
            status: 'Declined',
            comment: this.state.declineReason
        };
        this.updateStatusOnServer(dataToSend);
    };
    handleApprove = (formData) => {
        this.setState({loading: true, showApproveModal: false});
        formData.append('id', this.state.id);

        this.props.protectedFetch(config.backendServer+'approvewarranty', formData, res => res.ok, true)
            .then(res => {
                // On resolved and rejected, need to take off loading screen
                this.retrieveWarranty(this.state.id);
            }).catch(()=> {
            console.log('fail');
            this.setState({loading: false});
        });
    };
    handleNextStep = () => {
        const warrantySteps = (this.state.details && this.state.details.out_of_warranty) ? outOfWarrantySteps : inWarrantySteps;
        this.setState({loading: true, showNextStepModal: false});
        const dataToSend = {
            id: this.state.id,
            status: warrantySteps[this.state.details.status].next
        };
        this.updateStatusOnServer(dataToSend);
    };
    updateStatusOnServer(data) {
        this.props.protectedFetch(config.backendServer+'changewarrantystatus', data, errorFunctions.checkResponseSent)
            .then(res => {
                this.retrieveWarranty(data.id);
            }).catch(()=> {
            console.log('fail');
            this.setState({loading: false});
        });
    };

    handleUploadStartPostageLabel = (e) => {
        this.setState({loading: true});
        e.preventDefault();

        let formData = new FormData();
        formData.append('file', document.getElementById("uploadPostageLabel").files[0]);
        formData.append('id', this.state.id);

        this.props.protectedFetch(config.backendServer+'uploadstartpostagelabel', formData, res => res.ok, true)
            .then(res => {
                this.setState({addComment: ''});
                this.retrieveWarranty(this.state.id);
            }).catch(()=> {
            console.log('fail');
            this.setState({loading: false});
        });
    };
    handleSetEndPostageNumber = (e) => {
        this.setState({loading: true});
        e.preventDefault();

        const dataToSend = {
            id: this.state.id,
            number: this.state.endPostageNumber
        };

        this.props.protectedFetch(config.backendServer+'setendtrackingnumber', dataToSend, errorFunctions.checkResponseSent)
            .then(res => {
                this.setState({addComment: ''});
                this.retrieveWarranty(dataToSend.id);
            }).catch(()=> {
            console.log('fail');
            this.setState({loading: false});
        });
    };
    downloadFile = (type) => {
        const id = this.state.id;
        this.setState({loading: true});
        this.props.protectedFetch(config.backendServer+'downloadfile', {id, type}, res=>{
            return res;   // Do everything in the 'then'
        })
            .then(res => {
                if (res.ok) {
                    const filename = res.headers.get('FileName');
                    res.blob().then(res => {
                        const blob = res;
                        blob.name = filename;
                        utilityFunctions.downloadFileAutomatically(blob);
                    });
                }
                this.setState({loading: false});
            }).catch((err)=> {
            console.log('fail', err);
            this.setState({loading: false});
        });
    };
    handleDelete = () => {
        // Should only ever get here if a super user - just to make sure check
        if (this.props.isSuper) {
            this.setState({loading: true});
            this.props.protectedFetch(config.backendServer + 'deletewarranty', {id: this.state.id}, errorFunctions.checkResponseSent)
                .then(res => {
                    this.setState({loading: false, redirect: true});
                }).catch(() => {
                console.log('fail');
                this.setState({loading: false});
            });
        }
    };
    handleOutOfWarranty = () => {
        this.setState({loading: true, showOutOfWarrantyModal: false});
        const dataToSend = {
            id: this.state.id,
            status: 'Out of Warranty'
        };
        this.updateStatusOnServer(dataToSend);
    };
    handleFileUpload = e => {
        // TODO - verify file upload to make sure it's good
    };

    render() {
        if (this.state.redirect) {
            // Redirect only occurs when its a super user
            return <Redirect to={"/warranties"}/>;
        } else if (this.state.loading) {
            return <LoadingPage/>
        } else if (!this.state.details) {
            return <div className={"page"}>
                <h2 className={"pageHeader"}>Error - page not found</h2>
            </div>
        }

        const details = this.state.details || {};
        const warrantySteps = details.out_of_warranty ? outOfWarrantySteps : inWarrantySteps;
        const reason = JSON.parse(details.reason);
        const status = details.status;
        const history = details.history;

        const inWarrantyProgressBarValues = ['Approved', 'Under review', 'Under servicing', 'Complete'];
        const outOfWarrantyProgressBarValues = ['Out of Warranty', 'Approved', 'In transit', 'Under review', 'Approve invoice', 'Awaiting payment', 'Under servicing', 'Complete'];
        const progressBarValues = details.out_of_warranty ? outOfWarrantyProgressBarValues : inWarrantyProgressBarValues;
        const that = this;

        return <div className={"page"}>
            <h2 className={"pageHeader"}>Warranty Claim {this.state.id} for {details.number}</h2>
            {details.out_of_warranty && <div className={"warranty-out-of-warranty-flag"}>Out of Warranty</div>}
            <div className={"pageContent clearfix"}>
                {progressBarValues.indexOf(status) > -1 && <div
                    style={{
                        width: (progressBarValues.length * 80)+'px',
                        marginBottom: '10px'
                    }}
                >
                    <ProgressBar
                        values={progressBarValues}
                        active={progressBarValues.indexOf(status)}
                    />
                </div>}
                {warrantySteps[status] && warrantySteps[status].next &&
                    <button
                        className={"button"}
                        onClick={()=>{this.setState({showNextStepModal: true})}}
                    >{warrantySteps[status].buttonText}</button>
                }
                {status === 'Customer Review' && <div style={{color: '#4A148C'}}>Awaiting Customer Review</div>}
                <div className={"single-warranty-details"}>
                    <h4 className={"single-warranty-header"}>Warranty Details</h4>
                    <div className={"single-warranty-details-item"}>
                        <span className={"single-warranty-details-label"}>Ticket Number: </span>
                        <span>{details.ticket_number || ""}</span>
                    </div>
                    <div className={"single-warranty-details-item"}>
                        <span className={"single-warranty-details-label"}>Serial Number: </span>
                        <span>{details.number}</span>
                    </div>
                    <div className={"single-warranty-details-item"}>
                        <span className={"single-warranty-details-label"}>Device Name: </span>
                        <span>{details.device_name}</span>
                    </div>
                    <div className={"single-warranty-details-item"}>
                        <span className={"single-warranty-details-label"}>Owner: </span>
                        <span>{details.name}</span>
                    </div>
                    <div className={"single-warranty-details-item"}>
                        <span className={"single-warranty-details-label"}>Address: </span>
                        <span>{details.address}</span>
                    </div>
                    <div className={"single-warranty-details-item"}>
                        <span className={"single-warranty-details-label"}>Mobile: </span>
                        <span>{details.mobile}</span>
                    </div>
                    <div className={"single-warranty-details-item"}>
                        <span className={"single-warranty-details-label"}>Email: </span>
                        <span>{details.email}</span>
                    </div>
                    <div className={"single-warranty-details-item"}>
                        <span className={"single-warranty-details-label"}>Seller Details: </span>
                        <span>{details.seller_details}</span>
                    </div>
                    <div className={"single-warranty-details-item"}>
                        <span className={"single-warranty-details-label"}>Has proof of purchase?: </span>
                        <span>{details.proof_of_purchase ? 'Yes' : 'No'}</span>
                    </div>
                    {/* If proof of purchase, button to download */}
                    {details.proof_of_purchase && <button
                        className="button"
                        onClick={() => this.downloadFile('proof_of_purchase')}
                        style={{margin: '10px 0'}}
                    >Download Proof of Purchase</button>
                    }
                    <div className={"single-warranty-details-item"}>
                        <div className={"single-warranty-details-label"}>Problem details: </div>
                        <ul>
                            {reason && reason.map(function(item, i) {
                                return <li key={i}>{item}</li>;
                            })}
                        </ul>
                    </div>
                    <div className={"single-warranty-details-item"}>
                        <span className={"single-warranty-details-label"}>System Error: </span>
                        <span>{details.system_error || ""}</span>
                    </div>
                    {details.other_details && <div className={"single-warranty-details-item"}>
                        <span className={"single-warranty-details-label"}>Other problem details: </span>
                        <div style={{whiteSpace: 'pre-wrap'}}>{details.other_details}</div>
                    </div>}
                </div>
                <div className={"single-warranty-history-comments"}>
                    <h4 className={"single-warranty-header"}>History and comments</h4>
                    <ul>
                    {history.map(function(item, i) {
                        let text = '';
                        if (item.type === 'status') {
                            switch(item.status) {
                                case 'Approved':
                                    text = `Warranty created.`;
                                    break;
                                case 'Created':
                                    text = `Warranty created.`;
                                    break;
                                case 'In transit':
                                    text = `Item has been marked as sent by customer.`;
                                    break;
                                case 'Under review':
                                    text = `Item received by team.`;
                                    break;
                                case 'Under servicing':
                                    text = `Item has passed review process and now being serviced.`;
                                    break;
                                case 'Complete':
                                    text = `Item has been serviced and is now being sent back to customer.`;
                                    break;
                                case 'Declined':
                                    text = `Warranty has been declined because "${details.comments}".`;
                                    break;
                                case 'Out of Warranty':
                                    text = `Device has been marked as 'Out of Warranty'.`;
                                    break;
                                default:
                                // Don't do anything on default
                            }
                        } else if (item.type === 'comment') {
                            text = 'Comment - "' + item.comments +'"';
                        } else if (item.type === 'postage') {
                            switch(item.status) {
                                case 'firstReceive':  // in v1 - leave in so old warranties can still work
                                    text = `Postage tracking number added - ${item.comments}.`;
                                    break;
                                case 'endSentBack':
                                    text = `Postage tracking number added - ${item.comments}.`;
                                    break;
                                case 'firstReceiveLabel':
                                    text = <a href={"#"} onClick={() => that.downloadFile('start_postage_label')}>Postage label sent to user</a>;
                                    break;
                                default:
                                // Don't do anything on default
                            }
                        }
                        return <li key={i}>{item.date}: {text}</li>;
                    })}
                    </ul>
                    <form onSubmit={this.handleComment}>
                        <div className={"form-field"}>
                            <label>Add comment</label>
                            <textarea
                                required={true}
                                onChange={this.handleInputChange}
                                id={"addComment"}
                            />
                        </div>
                        <button
                            className="form-submit"
                            type="submit"
                        >Submit</button>
                    </form>
                </div>
                {this.props.isSuper && <button
                    className={"button deleteButton"}
                    onClick={()=>{this.setState({showDeleteModal: true})}}
                >Delete Warranty</button>}
            </div>
            {/* Confirmation modal for going to next step after approval */}
            {warrantySteps[status] && <Modal
                isOpen={this.state.showNextStepModal}
                contentLabel={"Continue to next step of warranty"}
                className={"confirm-modal"}
                style={ {overlay: {zIndex: 10}} }
            >
                <button onClick={()=>this.setState({showNextStepModal: false})} className={"confirm-modal-close"}><MdClose/></button>
                <div className={"confirm-main-text"}>{warrantySteps[status].modalText}</div>
                <div className={"approveDeclineSet confirmApproveDeclineSet"}>
                    <button onClick={this.handleNextStep} className={"approve confirmApprove"}>Yes</button>
                    <button className={"neutral confirmNeutral"} onClick={()=>this.setState({showNextStepModal: false})}>No</button>
                </div>
            </Modal>}
            {/* Delete modal */}
            {this.props.isSuper && <Modal
                isOpen={this.state.showDeleteModal}
                onRequestClose={()=>this.setState({showDeleteModal: false})}
                contentLabel={"Delete modal"}
                className={"delete-modal"}
            >
                <button onClick={()=>this.setState({showDeleteModal: false})} className={"modal-close"}><MdClose/></button>
                <div className={"delete-main-text"}>Are you sure you wish to delete this warranty?</div>
                <div className={"delete-sub-text"}>All information associated with warranty will be permanently deleted.</div>
                <div className={"approveDeclineSet confirmApproveDeclineSet"}>
                    <button onClick={this.handleDelete} className={"decline confirmDecline"}>Delete</button>
                    <button className={"neutral confirmNeutral"} onClick={()=>this.setState({showDeleteModal: false})}>Cancel</button>
                </div>
            </Modal>}
            {/* Modal to mark as 'Out of Warranty' */}
            <Modal
                isOpen={this.state.showOutOfWarrantyModal}
                contentLabel={"Set as Out of Warranty"}
                className={"confirm-modal"}
            >
                <button onClick={()=>this.setState({showOutOfWarrantyModal: false})} className={"confirm-modal-close"}><MdClose/></button>
                <div className={"confirm-main-text"}>Do you wish to set as "Out of Warranty"?</div>
                <div className={"approveDeclineSet confirmApproveDeclineSet"}>
                    <button onClick={this.handleOutOfWarranty} className={"approve confirmApprove"}>Yes</button>
                    <button className={"neutral confirmNeutral"} onClick={()=>this.setState({showOutOfWarrantyModal: false})}>Cancel</button>
                </div>
            </Modal>
        </div>
    }
}
