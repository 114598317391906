import React, { Component } from "react";
import "./HomeDashboard.css";
import "../common.css";
import config from '../../config';
import { Link } from "react-router-dom";
import errorFunctions from "../../store/errorFunctions";
import LoadingPage from "../../components/Loading/Loading";
import moment from 'moment';
import { FiUsers, FiLogIn } from "react-icons/fi";
import { IoMdHelpBuoy } from "react-icons/io";
import { TiSpannerOutline, TiArrowSortedDown, TiArrowSortedUp, TiMinus } from "react-icons/ti";

export default class HomeDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            table: null,
            log: null,
            filter: 'last_7_days'
        };
    };

    componentDidMount() {
        this.retrieveDashboardData();
    };
    retrieveDashboardData = () => {
        this.setState({loading: true});
        this.props.protectedFetch(config.backendServer + 'getdashboard', null, errorFunctions.checkResponseThenJson)
            .then(res => {
                this.setState({loading: false, table: res.table, log: res.log});
            }).catch(() => {
            console.log('fail');
            this.setState({loading: false});
        });
    };
    setFilter = filter => {
        this.setState({filter});
    };

    render() {
        if (this.state.loading) {
            return <LoadingPage/>
        }

        const tableData = this.state.table;
        const logData = this.state.log;

        const logIcons = {
            'warranty': {
                icon: <TiSpannerOutline/>,
                color: '#2E7D32'
            },
            'device': {
                icon: <IoMdHelpBuoy/>,
                color: '#FF8F00'
            },
            'user': {
                icon: <FiUsers/>,
                color: '#303F9F'
            },
        };

        return <div className={"page"}>
            <h2 className={"pageHeader"}>Summary</h2>
            <div className={"homeDashboardTimeSelectorSection"}>
                <div onClick={() => this.setFilter('last_7_days')} className={`homeDashboardTimeSelector ${this.state.filter === 'last_7_days' ? 'active' : ''}`}>7d</div>
                <div onClick={() => this.setFilter('last_30_days')} className={`homeDashboardTimeSelector ${this.state.filter === 'last_30_days' ? 'active' : ''}`}>30d</div>
                <div onClick={() => this.setFilter('total')} className={`homeDashboardTimeSelector ${this.state.filter === 'total' ? 'active' : ''}`}>All</div>
            </div>
            <div>
                <Link to={"/users"} className={"homeDashboardSummaryBox"} style={{
                    color: 'white',
                    background: '#e53935',
                    border: '1px solid #ffcdd2',
                    boxShadow: '1px 1px 1px 0 #ffcdd2'
                }}>
                    <div className={"homeDashboardSummaryIcon"}><FiUsers/></div>
                    <div className={"homeDashboardSummaryTitle"}>Users</div>
                    <div className={"homeDashboardSummaryNumber"}>{tableData.filter(obj => obj.type === 'Users')[0][this.state.filter]}</div>
                    {tableData.filter(obj => obj.type === 'Users')[0][this.state.filter+'_previous'] && <div className={"homeDashboardSummaryChange"}>
                        <div className={"homeDashboardSummaryChangeIcon"}>{tableData.filter(obj => obj.type === 'Users')[0][this.state.filter+'_previous'] >= 0 ? <TiArrowSortedUp/> : <TiArrowSortedDown/>}</div>
                        <div className={"homeDashboardSummaryChangeNumber"}>{Math.abs(tableData.filter(obj => obj.type === 'Users')[0][this.state.filter+'_previous'])}</div>
                    </div>}
                </Link>
                <div className={"homeDashboardSummaryBox"} style={{
                    color: 'white',
                    background: '#303F9F',
                    border: '1px solid #C5CAE9',
                    boxShadow: '1px 1px 1px 0 #C5CAE9'
                }}>
                    <div className={"homeDashboardSummaryIcon"}><FiLogIn/></div>
                    <div className={"homeDashboardSummaryTitle"}>User Login</div>
                    <div className={"homeDashboardSummaryNumber"}>{tableData.filter(obj => obj.type === 'User Login')[0][this.state.filter]}</div>
                    {tableData.filter(obj => obj.type === 'User Login')[0][this.state.filter+'_previous'] && <div className={"homeDashboardSummaryChange"}>
                        <div className={"homeDashboardSummaryChangeIcon"}>{tableData.filter(obj => obj.type === 'User Login')[0][this.state.filter+'_previous'] >= 0 ? <TiArrowSortedUp/> : <TiArrowSortedDown/>}</div>
                        <div className={"homeDashboardSummaryChangeNumber"}>{Math.abs(tableData.filter(obj => obj.type === 'User Login')[0][this.state.filter+'_previous'])}</div>
                    </div>}
                </div>
                <div className={"homeDashboardSummaryBox"} style={{
                    color: 'white',
                    background: '#FF6D00',
                    border: '1px solid #FFE082',
                    boxShadow: '1px 1px 1px 0 #FFE082'
                }}>
                    <div className={"homeDashboardSummaryIcon"}><IoMdHelpBuoy/></div>
                    <div className={"homeDashboardSummaryTitle"}>Devices registered</div>
                    <div className={"homeDashboardSummaryNumber"}>{tableData.filter(obj => obj.type === 'Devices')[0][this.state.filter]}</div>
                    {tableData.filter(obj => obj.type === 'Devices')[0][this.state.filter+'_previous'] && <div className={"homeDashboardSummaryChange"}>
                        <div className={"homeDashboardSummaryChangeIcon"}>{tableData.filter(obj => obj.type === 'Devices')[0][this.state.filter+'_previous'] >= 0 ? <TiArrowSortedUp/> : <TiArrowSortedDown/>}</div>
                        <div className={"homeDashboardSummaryChangeNumber"}>{Math.abs(tableData.filter(obj => obj.type === 'Devices')[0][this.state.filter+'_previous'])}</div>
                    </div>}
                </div>
                <Link to={"/warranties"} className={"homeDashboardSummaryBox"} style={{
                    color: 'white',
                    background: '#2E7D32',
                    border: '1px solid #C8E6C9',
                    boxShadow: '1px 1px 1px 0 #C8E6C9',
                    marginRight: 0
                }}>
                    <div className={"homeDashboardSummaryIcon"}><TiSpannerOutline/></div>
                    <div className={"homeDashboardSummaryTitle"}>Warranty Requests</div>
                    <div className={"homeDashboardSummaryNumber"}>{tableData.filter(obj => obj.type === 'Warranties')[0][this.state.filter]}</div>
                    {tableData.filter(obj => obj.type === 'Warranties')[0][this.state.filter+'_previous'] && <div className={"homeDashboardSummaryChange"}>
                        <div className={"homeDashboardSummaryChangeIcon"}>{tableData.filter(obj => obj.type === 'Warranties')[0][this.state.filter+'_previous'] >= 0 ? <TiArrowSortedUp/> : <TiArrowSortedDown/>}</div>
                        <div className={"homeDashboardSummaryChangeNumber"}>{Math.abs(tableData.filter(obj => obj.type === 'Warranties')[0][this.state.filter+'_previous'])}</div>
                    </div>}
                </Link>
            </div>
            <h3 className={"pageHeader"} style={{marginTop: '20px'}}>Latest Activity</h3>
            {logData.map((item, i) => {
                return <div key={i} className={"homeDashboardLogSingleAboveContainer"}>
                    <a href={item.link} className={"homeDashboardLogSingleContainer"}>
                        <div style={{backgroundColor: logIcons[item.icon].color}} className={"homeDashboardLogSingleIcon"}>{logIcons[item.icon].icon}</div>
                        <div className={"homeDashboardLogSingleTextContainer"}>
                            <div className={"homeDashboardLogSingleText"}>{item.comment}</div>
                            <div className={"homeDashboardLogSingleDate"}>{moment(item.date).fromNow()}</div>
                        </div>
                    </a>
                </div>
            })}
        </div>
    }
}
