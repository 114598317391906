import React, { Component } from "react";
import "./ProgressBar.css";

export default class ProgressBar extends Component {
    render() {
        const values = this.props.values;
        const active = this.props.active;
        return <div className={"clearfix"}>
            <div className="progressbar-wrapper">
                <ul className="progressbar">
                    {values.map(function(item, i) {
                        return <li
                            style={{width: (100/values.length)+'%'}}
                            className={i === active ? 'active' : (i < active) ? 'previous' : ''}
                            key={i}>{item}</li>;
                    })}
                </ul>
            </div>
        </div>
    }
}
